<template>
  <label class="d-flex">
    <span v-if="need" class="x-require-indicator font-medium-2 x-text-bold x-pr-4px">*</span>
    <div class="d-flex flex-row align-items-center">
      <span class="font-medium-1 x-text-bold text-body">{{ label + '：' }}</span>
      <span v-if="tip" v-tooltip.hover="tip" class="el-icon-question ml-50 text-secondary text-lighten-5 font-medium-1" ></span>
    </div>
  </label>
</template>

<script>
import {
  VBTooltip,
} from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    /**
     *  表格类型
     */
    label: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    require: {
      type: Boolean,
      default: undefined,
    },
  },
  data() {
    return {
      need: this.require,
    }
  },
  watch: {
    require(val) {
      this.need = val
    },
  },
}
</script>

<style lang="scss">
</style>
