<template>
  <validation-observer ref="formObserver">
    <b-form class="m-2">
      <b-row>
        <slot></slot>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BForm, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    ValidationObserver,
    BForm,
    BRow,
  },
  props: {
  },
  methods: {
    validate() {
      return this.$refs.formObserver.validate()
    },
    reset() {
      if (this.$refs.formObserver) {
        this.$refs.formObserver.reset()
      }
    },
  },
}
</script>

<style lang="scss">
</style>
