<template>
  <b-col :cols="cols">
    <b-form-group>
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="need ? 'required' : ''">
        <x-form-label :label="label" :require="need" :tip="tip"></x-form-label>
        <slot :errors="errors">
        </slot>
        <slot name="error" :errors="errors">
          <small class="text-danger" v-if="errors[0]">{{ label + '不能为空' }}</small>
        </slot>
      </validation-provider>
    </b-form-group>
  </b-col>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BFormGroup, BCol,
} from 'bootstrap-vue'
import XFormLabel from './XFormLabel.vue'

export default {
  components: {
    ValidationProvider,
    BFormGroup,
    BCol,
    XFormLabel,
  },
  props: {
    /**
     *  表格类型
     */
    label: {
      type: String,
      default: '',
    },
    tip: {
      type: String,
      default: '',
    },
    require: {
      type: Boolean,
      default: undefined,
    },
    cols: {
      type: String,
      default: '12',
    },
  },
  data() {
    return {
      required,
      need: this.require,
    }
  },
  watch: {
    require(val) {
      console.log(val)
      this.need = val
    },
  },
}
</script>

<style lang="scss">
</style>
